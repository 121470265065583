<template>
  <div class="w-full desktop-only">
    <p class="paragraph-1">
      We Accept
    </p>
    <div class="payment-methods">
      <img
        class="icon"
        v-for="card in commonPaymentMethods"
        :key="card.name"
        :src="card.path"
      >
      <template
        v-if="!onID"
      >
        <inline-svg
          class="icon"
          :src="require('common/assets/card-icons/american-express.svg')"
        />
        <inline-svg
          class="icon"
          :src="require('common/assets/google-pay.png')"
        />
        <svg
          class="icon"
        >
          <use xlink:href="#apple_pay" />
        </svg>
        <svg
          class="icon paypal"
        >
          <use xlink:href="#paypal_icon" />
        </svg>
        <inline-svg
          v-if="onMY"
          class="icon"
          :src="require('common/assets/card-icons/jcb.svg')"
        />
        <svg
          v-if="onSG || onMY"
          class="icon"
        >
          <use xlink:href="#shopback" />
        </svg>
        <inline-svg
          v-if="onSG"
          class="icon"
          :src="require('common/assets/paynow.svg')"
        />
        <inline-svg
          v-if="onUS"
          class="icon"
          :src="require('common/assets/klarna.svg')"
        />
      </template>
      <inline-svg
        v-else
        class="icon"
        v-for="card in idPaymentMethods"
        :key="card.name"
        :src="card.path"
      />
    </div>
  </div>
</template>
<script>
import isOnPage from 'common/mixins/isOnPage';
import VisaIcon from 'common/assets/card-icons/visa.svg'
import MasterCardIcon from 'common/assets/card-icons/mastercard.svg'
import JCBIcon from 'common/assets/card-icons/jcb.svg'
import BCAIcon from 'common/assets/va_banks_icons/payment-bca.svg'
import BNIIcon from 'common/assets/va_banks_icons/payment-bni.svg'
import MandiriIcon from 'common/assets/va_banks_icons/payment-mandiri.svg'
import PermataIcon from 'common/assets/va_banks_icons/payment-permata.svg'

export default {
  mixins: [isOnPage],
  computed: {
    commonPaymentMethods () {
      return [
        { name: 'visa', path: VisaIcon },
        { name: 'mastercard', path: MasterCardIcon }
      ]
    },
    idPaymentMethods () {
      const icons = [
        { name: 'jcb', path: JCBIcon },
        { name: 'bca', path: BCAIcon },
        { name: 'bni', path: BNIIcon },
        { name: 'mandiri', path: MandiriIcon },
        { name: 'permata', path: PermataIcon }
      ];
      return icons
    }
  }
}

</script>

<style lang="scss" scoped>
.w-full {
  width: 100%;
}

.icon {
  width: 40px;
  height: 26px;
}
.paypal {
  width: 70px;
}
.cards {
  height: 26px;
  width: 95px;
}
.payment-methods {
  margin-top: var(--spacing-xl);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-xl);
}
img {
  width: auto;
  height: 22px;
}
</style>
